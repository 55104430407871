<template>
    <div class="info">
        <top-bar :title="'服务经费'" :left="true"></top-bar>

        <van-popup v-model="yearDateShow" position="bottom">
            <van-datetime-picker :formatter="formatter" :columns-order="['month', 'year' , 'day']"  @confirm="yearDateConfim" @cancel="yearDateShow = false"
                                 type="date" title="选择年份" :min-date="new Date(2020, 0, 1)"
                                 :max-date="new Date(2030, 0, 1)"/>
        </van-popup>
        <van-popup v-model="monthDateShow" position="bottom">
            <van-datetime-picker :formatter="formatterMonth" :columns-order="['year' , 'month', 'day']" @confirm="monthDateConfim" @cancel="monthDateShow = false" title="选择月份"
                                 type="date" :min-date="new Date(2020, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
        </van-popup>

        <!--            <van-field clearable clear-trigger="always" v-model="dataForm.title" label="活动名称" placeholder="请输入" input-align="right" :readonly="isTasks"/>-->
        <!--            <van-field clearable clear-trigger="always" v-model="dataForm.address" label="活动地点" placeholder="请输入" input-align="right"/>-->
        <!--            <van-cell title="开始时间" :value="dataForm.beginTime || '请选择'" @click="yearDateShow = !yearDateShow"-->
        <!--                      :value-class="{'value-common':!dataForm.beginTime}" is-link />-->
        <!--            <van-cell title="结束时间" :value="dataForm.endTime || '请选择'"   @click="monthDateShow = !monthDateShow"-->
        <!--                      :value-class="{'value-common':!dataForm.endTime}" is-link />-->
        <!--            <van-field clearable clear-trigger="always" v-model="dataForm.joinNum" label="参与人数" placeholder="请输入" input-align="right"/>-->
        <!--            <van-field clearable clear-trigger="always" v-model="dataForm.speaker" label="主讲人" placeholder="请输入" input-align="right"/>-->
        <!--            <van-cell title="活动类型" :value="typeStr || '请选择'"  @click="!isTasks?typeShow = !typeShow : ''"-->
        <!--                      :value-class="{'value-common':!typeStr}" is-link />-->
        <!--            <van-field clearable clear-trigger="always" v-model="dataForm.content" rows="4" :border="false" label="活动详情" type="textarea" input-align="right" maxlength="50" placeholder="请输入"/>-->

        <van-cell-group>
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">记录年月</span>
                </template>
            </van-cell>
            <van-cell title="年" :value="dataForm.year + '年'" @click="yearDateShow = !yearDateShow"
                      :value-class="{'value-common':!dataForm.year}" :is-link="changeOrDetail"/>
            <van-cell title="月" :value="dataForm.month + '月'" @click="monthDateShow = !monthDateShow"
                      :value-class="{'value-common':!dataForm.month}" :is-link="changeOrDetail"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">节目慰问</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.holidayNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.holidayAmount" input-align="right"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">住院慰问</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.hospitalNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.hospitalAmount" input-align="right"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">丧事</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.funeralNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.funeralAmount" input-align="right"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">困难补助</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.difficultyNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.difficultyAmount" input-align="right"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">文娱健身活动</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.fitnessNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.fitnessAmount" input-align="right"/>
            <van-field clearable clear-trigger="always" label="次数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.fitnessTimes" input-align="right"/>
        </van-cell-group>

        <van-cell-group class="info-second">
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">自管小组活动</span>
                </template>
            </van-cell>
            <van-field clearable clear-trigger="always" label="人数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.groupNum" input-align="right"/>
            <van-field clearable clear-trigger="always" label="金额" placeholder="请输入" :readonly="!changeOrDetail" type="number" v-model="dataForm.groupAmount" input-align="right"/>
            <van-field clearable clear-trigger="always" label="次数" placeholder="请输入" :readonly="!changeOrDetail" type="digit" v-model="dataForm.groupTimes" input-align="right"/>
        </van-cell-group>

<!--        <van-row class="btns">-->
<!--            <van-col :span="24">-->
<!--                <van-button type="info" size="large" round @click="submit">完成</van-button>-->
<!--            </van-col>-->
<!--        </van-row>-->
        <van-row v-if="changeOrDetail" class="btns">
            <van-col :span="24">
                <van-button type="info" size="large" round @click="submit">完成</van-button>
            </van-col>
        </van-row>
        <van-row v-else class="btns" gutter="10">
            <van-col :span="12">
                <van-button plain type="danger" size="large" round @click="delt" style="background-color:transparent">删除</van-button>
            </van-col>
            <van-col :span="12">
                <van-button type="info" size="large" round @click="changeOrDetail = !changeOrDetail">编辑</van-button>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterYear} from '@/utils/utils'
import {formatterOnlyMonth} from '@/utils/utils'

export default {
    name: "ffsms-add",
    components: {
        topBar
    },
    data() {
        return {
            changeOrDetail: false,
            yearDateShow: false,
            monthDateShow: false,
            typeShow: false,
            isTasks: false,
            typeStr: '',
            typeList: [],
            dataForm: {
                id: "",
                community: this.$orgId,
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                //节日慰问人数
                holidayNum: "",
                //节日慰问金额
                holidayAmount: "",
                //住院探望人数
                hospitalNum: "",
                //住院探望金额
                hospitalAmount: "",
                //丧事人数
                funeralNum: "",
                //丧事金额
                funeralAmount: "",
                //困难补助人数
                difficultyNum: "",
                //困难补助金额
                difficultyAmount: "",
                //文娱健身活动人数
                fitnessNum: "",
                //文娱健身活动金额
                fitnessAmount: "",
                //文娱健身活动次数
                fitnessTimes: "",
                //自管小组活动人数
                groupNum: "",
                //自管小组活动金额
                groupAmount: "",
                //自管小组活动次数
                groupTimes: "",
            },
        }
    },
    methods: {
        // 获取详情
        getInfo () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/ffsms/info'),
                method: 'post',
                params: this.$http.adornParams({
                    id: this.dataForm.id
                })
            }).then(({data})=> {
                if (data && data.code === 0) {
                    this.dataForm.community = data.appFfsms.community
                    this.dataForm.year = data.appFfsms.year
                    this.dataForm.month = data.appFfsms.month
                    this.dataForm.holidayNum = data.appFfsms.holidayNum
                    this.dataForm.holidayAmount = data.appFfsms.holidayAmount
                    this.dataForm.hospitalNum = data.appFfsms.hospitalNum
                    this.dataForm.hospitalAmount = data.appFfsms.hospitalAmount
                    this.dataForm.funeralNum = data.appFfsms.funeralNum
                    this.dataForm.funeralAmount = data.appFfsms.funeralAmount
                    this.dataForm.difficultyNum = data.appFfsms.difficultyNum
                    this.dataForm.difficultyAmount = data.appFfsms.difficultyAmount
                    this.dataForm.fitnessNum = data.appFfsms.fitnessNum
                    this.dataForm.fitnessAmount = data.appFfsms.fitnessAmount
                    this.dataForm.fitnessTimes = data.appFfsms.fitnessTimes
                    this.dataForm.groupNum = data.appFfsms.groupNum
                    this.dataForm.groupAmount = data.appFfsms.groupAmount
                    this.dataForm.groupTimes = data.appFfsms.groupTimes
                } else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        // 删除数据
        delt () {
            this.$dialog.confirm({
                title: '删除',
                message: '确认删除吗',
            })
                .then(() => {
                    this.$http({
                        url: this.$http.adornUrl('/wxapp/ffsms/delete'),
                        method: 'post',
                        params: this.$http.adornParams({
                            ids:this.dataForm.id
                        })
                    }).then(({data})=> {
                        if (data.code == 0) {
                            this.$toast.success({message:'删除成功'})
                            this.$router.go(-1)
                        }
                    },err=> {this.$toast.error({message:'删除失败'})})
                })
                .catch(() => {
                });
        },
        init () {
            if (this.dataForm.id) {
                this.changeOrDetail = false
                this.getInfo()
            } else {
                this.changeOrDetail =  true
            }
        },
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }else{
                return null
            }
        },
        formatterMonth(type, val) {
            if (type === 'month') {
                return val + '月';
            }else{
                return null
            }
        },
        submit() {
            this.$toast.loading({duration: 0, message: '提交中...', forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl(this.dataForm.id === '' ? '/wxapp/ffsms/save' : '/wxapp/ffsms/update'),
                method: 'post',
                data: this.$http.adornData({
                    ...this.dataForm
                })
            }).then(({data}) => {
                this.$toast.clear()
                if (data.code === 0) {
                    this.$toast.success({message: '提交成功'})
                    this.$router.go(-1)
                } else {
                    this.$toast.fail({message: data.msg})
                }
            }, err => {
                this.$toast.error({message: '提交失败'})
            })
        },
        yearDateConfim: function (value) {
            console.log("添加时间格式----->", value)
            let d = new Date(this.dataForm.year)
            this.dataForm.year = formatterYear(value);
            this.yearDateShow = false
        },
        monthDateConfim(value) {
            let d = new Date(this.dataForm.month)
            this.dataForm.month = formatterOnlyMonth(value)
            this.monthDateShow = false
        },
    },
    created() {
        this.dataForm.id = this.$route.query.id || ''
        this.init();
    }
}
</script>
